
import { defineComponent, onMounted, ref } from "vue";
import { requiredField } from "@/util/form-rules";
import FormLoading from "@/components/layout/FormLoading.vue";
import useForm from "@/modules/useForm";
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import Curso from "@/interfaces/Curso";
import Turma from "@/interfaces/Turma";
import store from "@/store";
import DivisaoService from "@/services/auth/DivisaoService";
import CursoService from "@/services/admin/CursoService";
import TurmaService from "@/services/admin/TurmaService";
import BaseService from "@/services/admin/BaseService";
import router from "@/router";
import Divisao from "@/interfaces/Divisao";

interface Form {
  texto: string;
  fixado: boolean;
  turmas: string[];
  cursos: string[];
  divisao_id: string;
  file: string;
  data_fixado: string;
  data_exibicao: string;
}

const baseForm: Form = {
  texto: "",
  fixado: false,
  turmas: [],
  cursos: [],
  divisao_id: "",
  file: "",
  data_fixado: "",
  data_exibicao: "",
};

export default defineComponent({
  components: { FormLoading },
  setup(props, { emit }) {
    const $q = useQuasar();
    const cursos = ref<Curso[]>([]);
    const turmas = ref<Turma[]>([]);
    const tipo = ref<string>("Geral");
    const form = ref<Form>(baseForm);
    const divisoes = ref<Divisao[]>([]);
    const { show, loading, destroy, afterSubmit } = useForm(
      "postagens",
      "postagens",
      "a",
      baseForm,
      emit,
      "postagens"
    );

    const getCursos = (): void => {
      loading.value = true;
      if (store.getters.divisaoId) {
        DivisaoService.getCursos(store.getters.divisaoId)
          .then((res) => {
            cursos.value = res.data;
          })
          .catch((err) => {
            console.error(err.response.data.message);
            showNotify({
              type: "negative",
              message: err.response.data.message,
            });
          })
          .finally(() => (loading.value = false));
      } else {
        CursoService.list()
          .then((res) => {
            cursos.value = res.data;
          })
          .catch((err) => {
            console.error(err.response.data.message);
            showNotify({
              type: "negative",
              message: err.response.data.message,
            });
          })
          .finally(() => (loading.value = false));
      }
    };

    const getTurmas = (): void => {
      loading.value = true;
      if (store.getters.divisaoId) {
        DivisaoService.getTurmas(store.getters.divisaoId)
          .then((res) => {
            turmas.value = res.data;
          })
          .catch((err) => {
            console.error(err.response.data.message);
            showNotify({
              type: "negative",
              message: err.response.data.message,
            });
          })
          .finally(() => (loading.value = false));
      } else {
        TurmaService.list()
          .then((res) => {
            turmas.value = res.data;
          })
          .catch((err) => {
            console.error(err.response.data.message);
            showNotify({
              type: "negative",
              message: err.response.data.message,
            });
          })
          .finally(() => (loading.value = false));
      }
    };

    const handleTipo = (): void => {
      form.value.turmas = [];
      form.value.cursos = [];
    };

    const showNotify = (opts: any): void => {
      $q.notify(opts);
    };

    const getDivisoes = (): void => {
      BaseService.list("divisoes")
        .then((res) => {
          divisoes.value = res.data;
        })
        .catch((err) => {
          console.error(err.response.data.message);
        });
    };

    const buildFormData = (
      formData: any,
      data: any,
      parentKey: any = null
    ): FormData => {
      if (
        data &&
        typeof data === "object" &&
        !(data instanceof Date) &&
        !(data instanceof File)
      ) {
        Object.keys(data).forEach((key) => {
          buildFormData(
            formData,
            data[key],
            parentKey ? `${parentKey}[${key}]` : key
          );
        });
      } else {
        const value = data == null ? "" : data;
        formData.append(parentKey, value);
      }
      return formData;
    };

    const submit = (): void => {
      let formData = new FormData();
      formData = buildFormData(formData, form.value);
      formData.append("file", form.value.file);
      BaseService.store("postagens", formData)
        .then(() => {
          showNotify({
            type: "positive",
            message: `Postagem corrigida com sucesso`,
          });
          router.push("area-postagem");
        })
        .catch((err) => {
          console.error(err.response.data.message);
          showNotify({
            type: "negative",
            message: "Erro ao corrigir postagem",
          });
        });
    };
    onMounted(() => {
      form.value = {
        texto: "",
        fixado: false,
        turmas: [],
        cursos: [],
        divisao_id: "",
        file: "",
        data_fixado: "",
        data_exibicao: "",
      };
      if (store.getters.divisaoId) {
        form.value.divisao_id = store.getters.divisaoId;
      }
      getCursos();
      getDivisoes();
      getTurmas();
    });

    return {
      tipo,
      show,
      open,
      form,
      requiredField,
      loading,
      submit,
      destroy,
      afterSubmit,
      handleTipo,
      cursos,
      getCursos,
      turmas,
      getTurmas,
      divisoes,
      store,
    };
  },
});
